import {HttpErrorResponse} from '@angular/common/http';
import {ApolloError} from '@apollo/client/core';
import {captureException, captureMessage, Severity} from '@sentry/browser';

import {environment} from '../../../environments/environment';
import {GraphqlErrors} from '../../grapqhl/graphql-error-handler';

interface ErrorCodeDeterminator {
    code: string;
    log: boolean;
}

export class Logger {

    static error(input: string | {
        error: unknown;
        info?: any;
        message: string;
        report?: boolean;
    }): void {
        // eslint-disable-next-line no-console
        console.error(input);

        if (!environment.sentry.enabled) {
            return;
        }

        if (typeof input === 'string') {
            captureMessage(input);
        } else if (input.report !== true) {
            captureException(input.error, {
                extra: {
                    message: input.message,
                    ...input.info,
                },
            });
        }
    }

    static errorWrap(error: Error): void;
    static errorWrap(message: string, info?: any): (err: Error) => void;
    static errorWrap(
        errorOrMessage: Error | string,
        info?: any,
    ): ((err: Error) => void) | undefined {
        if (errorOrMessage instanceof Error) {
            Logger.error({
                error: errorOrMessage,
                message: errorOrMessage.message,
            });
            return;
        }

        return error => {
            Logger.error({
                error,
                info,
                message: errorOrMessage,
            });
        };
    }

    static handleRequestError({defaultCode, error, info, message}: {
        defaultCode?: string;
        error: Error;
        info?: any;
        message: string;
    }): string {
        let result: string | null = null;
        let log = true;

        function assignResultAndLog(input?: ErrorCodeDeterminator | null): void {
            if (input == null) {
                return;
            }

            result = input.code;
            log = input.log;
        }

        if (error instanceof HttpErrorResponse) {
             assignResultAndLog(this.getErrorCodeFromHttpErrorResponse(error));
        } else if (error instanceof ApolloError) {
            if (error.networkError !== null) {
                if (error.networkError instanceof HttpErrorResponse) {
                    assignResultAndLog(this.getErrorCodeFromHttpErrorResponse(error.networkError));
                }
            }
        } else if (error instanceof GraphqlErrors) {
            log = error.isUnexpected();

            if (error.hasErrorCode('Unauthenticated')) {
                result = 'Error.Unauthenticated';
            } else if (error.hasErrorCode('Unauthorized')) {
                result = 'Error.Unauthorized';
            }
        }

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (log) {
            Logger.error({
                error,
                info,
                message,
            });
        } else {
            // eslint-disable-next-line no-console
            console.error({
                error,
                info,
                message,
            });
        }

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        return result ?? defaultCode ?? 'Error.Unknown';
    }

    static warn(message: string, info: Record<string, any>): void {
        // eslint-disable-next-line no-console
        console.warn(message, info);
        captureMessage(message, {
            extra: {
                ...info,
            },
            level: Severity.Warning,
        });
    }

    private static getErrorCodeFromHttpErrorResponse(
        error: HttpErrorResponse,
    ): ErrorCodeDeterminator | null {
        if (error.status === 0) {
            return {
                code: navigator.onLine ? 'Error.Cannot reach' : 'Error.Offline',
                log: navigator.onLine,
            };
        }

        return null;
    }
}
